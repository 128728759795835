<template>
    <div id="box">
        <Header :headerIndex="4" :nextLevel="7"></Header>
        <div class="container">
            <div class="flex-box flex-col-start ptb30 m1340 flex-col-stretch">
                <LeftNav :leftIndex="7"></LeftNav>
                <div class="flex-grow-1 ml20 pt30 plr30 personal message">
                    <div class="fs20 col10 fwb pb25">{{$t('message')}}</div>
                    <div class="msg_item mb20" v-for="item in list">
                        <div><span class="fs20 col10 fwb pr30">{{item.type==1?'系统通知':(item.type==2?'Rp账户变更':(item.type==3?'Ep账户变更':(item.type==4?'Pp账户变更':(item.type==5?'Ap账户变更':(item.type==6?'代币账户变更':'')))))}}</span><span class="fs16 col5">{{item.createtime_text}}</span></div>
                        <div class="fs16 col3 pt20">{{item.content}}</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { numberToCurrency } from "../assets/filters";
export default {
    filters: {
        numberToCurrency
    },
    data() {
        return {
           list:{}
        };
    },
    created(){
        this.initdata()
    },
    methods: {
       initdata(){
            this.getlist()
       },
       getlist(){
        this.$api.apiMessagelist({}).then(ret=>{
            if(ret.code==1){
                this.list=ret.data
            }else{
                // this.$message.error(ret.msg)
                this.$message({
                    message: ret.msg,
                    type: 'error',
                    offset: window.screen.height / 2
                })
            }
        })
       }
    }
};
</script>